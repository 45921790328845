var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.submitAddProduct($event)}}},[_c('b-form-group',{attrs:{"label":"ID","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-title","name":"register-title","state":errors.length > 0 ? false : null,"placeholder":"请输入标题"},model:{value:(_vm.formData.id),callback:function ($$v) {_vm.$set(_vm.formData, "id", $$v)},expression:"formData.id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"标题","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"标题","vid":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-title","name":"register-title","state":errors.length > 0 ? false : null,"placeholder":"请输入标题"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"大类","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"大类","vid":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vSelect',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"reduce":function (item) { return item.value; },"placeholder":'请选择大类',"options":[
                  { label: '人寿', value: 1 },
                  { label: '基金', value: 2 } ],"state":errors.length > 0 ? false : null,"options-label":'label',"options-value":'value'},model:{value:(_vm.formData.type.type_id),callback:function ($$v) {_vm.$set(_vm.formData.type, "type_id", $$v)},expression:"formData.type.type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"公司","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"公司品牌","vid":"brand_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vSelect',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"placeholder":"请选择公司","label":"title","reduce":function (item) { return item.id; },"filterable":false,"options":_vm.pprod,"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.brand_id),callback:function ($$v) {_vm.$set(_vm.formData, "brand_id", $$v)},expression:"formData.brand_id"}},[_c('li',{staticClass:"pagination_vselest",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('b-button',{attrs:{"disabled":!_vm.hasPrevPage},on:{"click":function($event){$event.preventDefault();_vm.pages--}}},[_vm._v("上一页")]),_c('b-button',{attrs:{"disabled":!_vm.hasNextPage},on:{"click":function($event){$event.preventDefault();_vm.pages++}}},[_vm._v("下一页")])],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"类型","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","rules":"required","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"产品类型","vid":"类型ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('vSelect',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"placeholder":"请选择类型","label":"title","reduce":function (item) { return item.id; },"filterable":false,"options":_vm.classList,"state":errors.length > 0 ? false : null,"options-value":'value'},model:{value:(_vm.formData.cate_id),callback:function ($$v) {_vm.$set(_vm.formData, "cate_id", $$v)},expression:"formData.cate_id"}},[_c('li',{staticClass:"pagination_vselest",attrs:{"slot":"list-footer"},slot:"list-footer"},[_c('b-button',{attrs:{"disabled":!_vm.hasPrevPageClass},on:{"click":function($event){$event.preventDefault();_vm.pages--}}},[_vm._v("上一页")]),_c('b-button',{attrs:{"disabled":!_vm.hasNextPageClass},on:{"click":function($event){$event.preventDefault();_vm.pages++}}},[_vm._v("下一页")])],1)]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"产品等级","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"产品等级","vid":"pro_level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"pro_level","state":errors.length > 0 ? false : null,"placeholder":"请输入等级"},model:{value:(_vm.formData.pro_level),callback:function ($$v) {_vm.$set(_vm.formData, "pro_level", $$v)},expression:"formData.pro_level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"派发率(%)","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"派发率(%)","vid":"rate","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-rate","name":"register-rate","state":errors.length > 0 ? false : null,"placeholder":"请输入派发率(%)"},model:{value:(_vm.formData.rate),callback:function ($$v) {_vm.$set(_vm.formData, "rate", $$v)},expression:"formData.rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"超额派发率(%)","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"派发率(%)","vid":"rate","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-rate","name":"register-rate","state":errors.length > 0 ? false : null,"placeholder":"请输入超额派发率(%)"},model:{value:(_vm.formData.over_rate),callback:function ($$v) {_vm.$set(_vm.formData, "over_rate", $$v)},expression:"formData.over_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"续费派发率(%)","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"续费派发率(%)","vid":"renew_rate","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-renew_rate","state":errors.length > 0 ? false : null,"name":"register-renew_rate","placeholder":"请输入续费派发率(%)"},model:{value:(_vm.formData.renew_rate),callback:function ($$v) {_vm.$set(_vm.formData, "renew_rate", $$v)},expression:"formData.renew_rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"状态","label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10","label-align":"center"}},[_c('validation-provider',{attrs:{"name":"状态","vid":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{class:[errors.length > 0 ? 'style-chooser' : 'style-chooser-none'],attrs:{"tabindex":1,"reduce":function (item) { return item.value; },"placeholder":'请选择状态',"state":errors.length > 0 ? false : null,"options":[
                  { label: '正常', value: 1 },
                  { label: '禁用', value: 0 } ]},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"1","label-cols-md":"2","content-cols":"6","content-cols-md":"10"}},[_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":_vm.isLoading}},[_vm._v("确认")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }