<!-- @format -->

<template>
  <b-card>
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="6">
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form mt-2" @submit.prevent="submitAddProduct">
            <b-form-group label="ID" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="ID" rules="required">
                <b-form-input id="register-title" v-model="formData.id" name="register-title" :state="errors.length > 0 ? false : null" placeholder="请输入标题" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="标题" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="标题" vid="title" rules="required">
                <b-form-input id="register-title" v-model="formData.title" name="register-title" :state="errors.length > 0 ? false : null" placeholder="请输入标题" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="大类" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="大类" vid="type" rules="required">
                <vSelect
                  :reduce="item => item.value"
                  v-model="formData.type.type_id"
                  :placeholder="'请选择大类'"
                  :options="[
                    { label: '人寿', value: 1 },
                    { label: '基金', value: 2 },
                  ]"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :state="errors.length > 0 ? false : null"
                  :options-label="'label'"
                  :options-value="'value'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="公司" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="公司品牌" vid="brand_id" rules="required">
                <vSelect
                  v-model="formData.brand_id"
                  placeholder="请选择公司"
                  label="title"
                  :reduce="item => item.id"
                  :filterable="false"
                  :options="pprod"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :state="errors.length > 0 ? false : null"
                >
                  <li slot="list-footer" class="pagination_vselest">
                    <b-button :disabled="!hasPrevPage" @click.prevent="pages--">上一页</b-button>
                    <b-button :disabled="!hasNextPage" @click.prevent="pages++">下一页</b-button>
                  </li>
                </vSelect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="类型" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" rules="required" label-align="center">
              <validation-provider #default="{ errors }" name="产品类型" vid="类型ID" rules="required">
                <!--                <b-form-select v-model="formData.cate_id" :placeholder="'请选择类型'" :options="classList" :state="errors.length > 0 ? false : null" />-->
                <vSelect
                  v-model="formData.cate_id"
                  placeholder="请选择类型"
                  label="title"
                  :reduce="item => item.id"
                  :filterable="false"
                  :options="classList"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :state="errors.length > 0 ? false : null"
                  :options-value="'value'"
                >
                  <li slot="list-footer" class="pagination_vselest">
                    <b-button :disabled="!hasPrevPageClass" @click.prevent="pages--">上一页</b-button>
                    <b-button :disabled="!hasNextPageClass" @click.prevent="pages++">下一页</b-button>
                  </li>
                </vSelect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="产品等级" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="产品等级" vid="pro_level" rules="required">
                <b-form-input v-model="formData.pro_level" name="pro_level" :state="errors.length > 0 ? false : null" placeholder="请输入等级" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="派发率(%)" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="派发率(%)" vid="rate" rules="required|number">
                <b-form-input id="register-rate" v-model="formData.rate" name="register-rate" :state="errors.length > 0 ? false : null" placeholder="请输入派发率(%)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="超额派发率(%)" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="派发率(%)" vid="rate" rules="required|number">
                <b-form-input id="register-rate" v-model="formData.over_rate" name="register-rate" :state="errors.length > 0 ? false : null" placeholder="请输入超额派发率(%)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- email -->
            <b-form-group label="续费派发率(%)" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="续费派发率(%)" vid="renew_rate" rules="required|number">
                <b-form-input id="register-renew_rate" v-model="formData.renew_rate" :state="errors.length > 0 ? false : null" name="register-renew_rate" placeholder="请输入续费派发率(%)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--            <b-form-group label="分担百分比" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">-->
            <!--              <validation-provider #default="{ errors }" name="百分比" vid="share_rate" rules="required|number">-->
            <!--                <b-form-input id="register-share_rate" v-model.number="formData.share_rate" :state="errors.length > 0 ? false : null" placeholder="请输入百分比" name="register-share_rate" disabled />-->
            <!--                <small class="text-danger">{{ errors[0] }}</small>-->
            <!--              </validation-provider>-->
            <!--            </b-form-group>-->
            <b-form-group label="状态" label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10" label-align="center">
              <validation-provider #default="{ errors }" name="状态" vid="gender" rules="required">
                <v-select
                  v-model="formData.status"
                  :tabindex="1"
                  :reduce="item => item.value"
                  :placeholder="'请选择状态'"
                  :state="errors.length > 0 ? false : null"
                  :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                  :options="[
                    { label: '正常', value: 1 },
                    { label: '禁用', value: 0 },
                  ]"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!--确认-->
            <b-form-group label-cols="1" label-cols-md="2" content-cols="6" content-cols-md="10">
              <b-button type="submit" variant="success" :disabled="isLoading">确认</b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import { BRow, BCol, BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue'
import store from '@/store/index'
import { onMounted, ref, getCurrentInstance, } from '@vue/composition-api'
import ListManagerModule from '@/views/GeneralManagement/ProductManager/List/ListManagerModule'
import { required, number } from '@core/utils/validations/validations'

import { useToast } from 'vue-toastification/composition'
import mix from '@/views/GeneralManagement/ProductManager/mix'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    vSelect,
    ValidationObserver,
    BButton,
  },
  mixins: [mix],
  setup() {
    const { proxy } = getCurrentInstance()
    const toast = useToast()

    const formData = ref({})

    async function getDetail() {
      axiosIns.get('/product/detail', {
        params: {
          product_id: proxy.$route.query.id,
        },
      }).then(res => {
        formData.value = res.data
        proxy.pprod.push(res.data.brand)
      })
    }

    const isLoading = ref(false)
    const submitAddProduct = () => {
      isLoading.value = true
      proxy.$refs.registerForm.validate().then(isOk => {
        if (isOk) {
          const INVOICE_APP_STORE_MODULE_NAME = 'list-module'
          if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ListManagerModule)
          const editData = {
            title: '',
            type: '',
            brand_id: '',
            cate_id: '',
            pro_level: '',
            id: '',
            rate: '',
            renew_rate: '',
            // share_rate: '1.06',
            status: '',
            over_rate: ''
          }
          Object.keys(editData).forEach(key => {
            if (formData.value[key]) {
              editData[key] = formData.value[key]
            }
          })

          editData.type = formData.value.type.type_id
          store
            .dispatch(`${INVOICE_APP_STORE_MODULE_NAME}/productionEdit`, editData)
            .then(response => {
              if (response.code === 0) {
                toast.success(response.msg || '添加成功')
                setTimeout(() => {
                  isLoading.value = false
                  proxy.$router.back()
                }, 1000)
              } else {
                isLoading.value = false
                toast.error(response.msg)
              }
            })
            .catch(() => {
              toast.error('添加失败')
              isLoading.value = false
            })
        } else {
          toast.error('请将表单填写完整')
          isLoading.value = false
        }
      })
    }
    onMounted(() => {
      Promise.all([proxy.getProductList(), proxy.getClassList()])
      getDetail()
    })

    return {
      formData,
      isLoading,
      submitAddProduct,

      required,
      number,
    }
  },
}
</script>

<style lang="scss">
.style-chooser .vs__search::placeholder {
  color: #ccc;
}
.style-chooser .vs__dropdown-toggle {
  border: 1px solid red;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
</style>
